import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
// import { Link } from 'gatsby';
import { Carousel } from 'antd';
import SiteLayout from '@layouts/site-layout';
import BlogRoll from '../../components/BlogRoll';
const axios = require('axios');

const our_partner = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
};

// export default class BlogIndexPage extends React.Component {
class BlogIndexPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    async sendData(posts) {
        let curSiteUrl = window.location.href
        let finalSiteUrl = curSiteUrl.replace(window.location.pathname, "");
        let masterArr = [];
        let finalArr = [];
        let masterGradeArr = [];
        let masterBoardArr = [];

        posts.forEach(function (i, v) {
            if (masterGradeArr.indexOf(i['node']['frontmatter']['grade']) === -1) {
                if ((i['node']['frontmatter']['grade']) !== null) {
                    masterGradeArr.push(i['node']['frontmatter']['grade']);
                }
            }
            if (masterBoardArr.indexOf(i['node']['frontmatter']['board']) === -1) {
                if ((i['node']['frontmatter']['board']) !== null) {
                    masterBoardArr.push(i['node']['frontmatter']['board']);
                }
            }
        })

        let boardDetails = [];
        masterBoardArr.forEach(function (j, k) {
            let boardObj = {}
            masterGradeArr.forEach(function (i, v) {
                let finalGradeObj = {};
                finalGradeObj['boardId'] = masterBoardArr[k]
                finalGradeObj['gradeId'] = masterGradeArr[v];
                finalGradeObj['posts'] = [];
                posts.forEach(function (i1, v1) {

                    if (i1['node']['frontmatter']['hiddenpost'] === true) {
                        if ((masterGradeArr[v] == i1['node']['frontmatter']['grade']) && (masterBoardArr[k] == i1['node']['frontmatter']['board'])) {
                            let dataObj = {};
                            dataObj.title = i1['node']['frontmatter']['title'];
                            dataObj.external_url = finalSiteUrl + i1['node']['fields']['slug'];
                            dataObj.thumbnail_url = i1['node']['frontmatter']['featuredimage'] !== null ? finalSiteUrl + i1['node']['frontmatter']['featuredimage']['childImageSharp']['fluid']['src'] : ''
                            dataObj.short_description = i1['node']['excerpt'];
                            dataObj.gradeId = i1['node']['frontmatter']['grade'];
                            dataObj.boardId = i1['node']['frontmatter']['board'];
                            finalGradeObj['posts'].push(dataObj);
                        }
                    }
                })
                if (finalGradeObj['posts'].length > 0) {
                    masterArr.push(finalGradeObj)
                }
            })
        })
        console.log(masterArr);
        console.log("FINAL OBJECT", masterArr);
        // console.log("masterGradeArr", masterGradeArr);
        // console.log("masterBoardArr", masterBoardArr);
        let sendData = await axios.post('https://8ytwl5trrl.execute-api.ap-south-1.amazonaws.com/PROD/blogposts', masterArr);
        console.log('SEND DATA', sendData);
    }
    componentDidMount() {
        const { data } = this.props
        const { edges: posts } = data.allMarkdownRemark
        this.sendData(posts);
    }
    render() {
        return (
            <SiteLayout title="Blogs">
                <div className="top_margin_comn">
                    <div className="comn_bkgimg home_slider_sec"
                        style={{ background: "url('../img/abt_banner_slide.jpg') no-repeat center center /cover", color: "#fff" }}
                    >
                        <div className="container">
                            <h3>Blog</h3>
                        </div>
                    </div>

                </div>



                {/* <div
                    className="full-width-image-container margin-top-0"
                    style={{
                        backgroundImage: `url('/img/blog-index.jpg')`,
                    }}
                >
                    <h1
                        className="has-text-weight-bold is-size-1"
                        style={{
                            boxShadow: '0.5rem 0 0 #f40, -0.5rem 0 0 #f40',
                            backgroundColor: '#f40',
                            color: 'white',
                            padding: '1rem',
                        }}
                    >
                        Latest Blogs
                    </h1>
                </div> */}
                <section className="blog_section">
                    <div className="container">
                        <div className="content">
                            <BlogRoll />
                        </div>
                    </div>
                </section>






                {/* <div className="blog_section">
         <div className="container">
            <h4 className="commn_heading">STEPapp Insight</h4>
            <p className="blog_para">Our Latest Thinking on the issues that matter the most in learning and education.</p>
             <div className="row">  
            <div className="col-md-8">
               <div className="blog_detail_area">
                  <h3 className="mb-4">How gamification is the future of Education</h3>
                  <div className="blog_img">
                      <img src="img/How-Gamification-is-the-future-of-Education.jpg" alt="2020-Starts-With-a-Revolutionizing-Scholarship-Program-STEPapp-Scholarships" />
                  </div>
                  <div className="date_box">
                     <p><img src="img/calander.png" alt="blog img" /> February 27, 2020</p>
                  </div>
                  <h6>1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </h6> 
                  <p>In a country like India, talent is not a hurdle. According to UNFPA India has the youngest population in the world and can still have one of the youngest populations until 2030. This means that our generation is blessed to be the guardians of the most important batch of nation-builders. The young minds of this country, given the right support and guidance, have more than what it takes to build a bright future not just for themselves, but for this country </p>  
                  <h6>2. Lorem ipsum dolor sit amet, consectetur adipisicing elit. </h6>
                  <p>In a country like India, talent is not a hurdle. According to UNFPA India has the youngest population in the world and can still have one of the youngest populations until 2030. This means that our generation is blessed to be the guardians of the most important batch of nation-builders. The young minds of this country, given the right support and guidance, have more than what it takes to build a bright future not just for themselves, but for this country </p>  
               </div>
             </div>
             <div className="col-md-4">
               <div className="section-title">
                  <h5 className="">Related Post</h5>
                  <div className="gry_bkg categ_sec">
                     <ul>
                        <li><Link to="/">Benefits of Gamified Learning.</Link></li> 
                        <li><Link to="/">Curiosity – A Fuel for Math and Science</Link></li> 
                        <li><Link to="/">Role of Scholarship in a Student’s Academic Life</Link></li> 
                        <li><Link to="/">Why is self-reliance important for our children?</Link></li> 
                        <li><Link to="/">Why STEPapp is the best learning app for students?</Link></li> 
                        <li><Link to="/" >How Gamification is the future of Education?</Link></li>
                     </ul>
                  </div>
               </div> 
            </div>
            </div> 
         </div>
     </div> */}


                {/* <div className="bkg_blue our_team_org blog_slide">
            <div className="container">   
                <Carousel {...our_partner} className="row patnar-slide our_team_sec">
                <div>
                    <div className="col-md-12">
                        <div className="blog_text_area">
                        <Link to="/" className="color_black">
                            <div className="blog_img"><img src="img/How-Gamification-is-the-future-of-Education.jpg" alt="blog-image-missing" /></div>
                            <h3>Benefits of Gamified Learning.</h3>
                        </Link>
                        <p>In the age of digital development, it is important for the education industry to keep up as well. One of the ways the education industry is enhancing themselves is by gamification of learning.</p>
                        <Link to="/" className="btn btn-pill btn-info mt-3">Read More</Link>
                        </div>
                        </div>
                    </div>  
                    <div>
                        <div className="col-md-12">
                            <div className="blog_text_area">
                            <a to="/" className="color_black">
                                <div className="blog_img"><img src="img/How-Gamification-is-the-future-of-Education.jpg"  alt="blog-image-missing" /></div>
                                <h3>Role of Scholarship in a Student’s Academic Life</h3>
                            </a>
                            <p>Scholarships provide an opportunity for many students to afford a great education. For talented students who are not economically well off, scholarships can be a blessing. </p>
                            <Link to="/" className="btn btn-pill btn-info mt-3">Read More</Link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="col-md-12">
                            <div className="blog_text_area">
                            <Link to="/" className="color_black">
                                <div className="blog_img">
                                <img  src="img/How-Gamification-is-the-future-of-Education.jpg"  alt="blog-image-missing" /></div>
                                <h3>How Gamification is the future of Education?</h3>
                            </Link>
                            <p>By gamification, we mean re-designing your curriculum into games to ensure that it not only increases the engagement of the learner but also helps them understand the real-world application of the concepts that they are learning. </p>
                            <Link to="/" className="btn btn-pill btn-info mt-3">Read More</Link>
                            </div>
                        </div>
                    </div> 
                    <div>
                        <div className="col-md-12">
                            <div className="blog_text_area">
                                <Link to="/" className="color_black">
                                    <div className="blog_img">
                                        <img src="img/How-Gamification-is-the-future-of-Education.jpg" alt="blog-image-missing" /></div>
                                    <h3>How Gamification is the future of Education?</h3>
                                </Link>
                                <p>By gamification, we mean re-designing your curriculum into games to ensure that it not only increases the engagement of the learner but also helps them understand the real-world application of the concepts that they are learning. </p>
                                <Link to="/" className="btn btn-pill btn-info mt-3">Read More</Link>
                            </div>
                        </div>
                    </div> 
                </Carousel>
            </div> 
        </div> */}
                {/* </div>   */}

            </SiteLayout>
        )
    }
}

BlogIndexPage.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array,
        }),
    }),
}


export default () => (
    <StaticQuery
        query={graphql`
      query BlogIndexPageQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" }} }
        ) {
          edges {
            node {
              excerpt(pruneLength: 200)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                hiddenpost
                grade
                board
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
        render={(data, count) => <BlogIndexPage data={data} count={count} />}
    />
)

